<template>
  <div class="content">
    <div class="contentbox"></div>
  </div>
</template>

<script>
export default {
  name: "blank",
  data() {
    return {};
  },
  methods: {
    getFirstPath(data) {
      if (data && data.length) {
        if (data[0].children && data[0].children.length) {
          return this.getFirstPath(data[0].children);
        } else {
          return data[0];
        }
      }
    },
    // 设置基本信息左侧菜单
    getBaseFillForm(val) {
      return new Promise(reslove => {
        this.$api("common.getBaseFillForm")
          .invoke({ fkEntid: val })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    children: [],
                    icon: "iconyuanquan",
                    // menuid: "a" + (i + 2),
                    menuid: e.pkId,
                    menuname: e.menuname,
                    menutitle: e.menuname,
                    navigateurl: e.formurl,
                    sysmodel: "baseInfo"
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateMenus", { data: arr });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    },
    getEntMenuList(val) {
      return new Promise(reslove => {
        this.$api("common.getEntMenuList")
          .invoke({ fkEntid: val })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    children: [],
                    icon: "iconyuanquan",
                    // menuid: "q" + (i + 1),
                    menuid: e.pkId,
                    menuname: e.menuname,
                    menutitle: e.menuname,
                    navigateurl: e.formurl,
                    sysmodel: "qiangdufenxi"
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateQdMenus", {
                  data: arr,
                  tongjiId: this.VUE_APP_TONGJIID
                });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    },
    getEntLyEnumList(val) {
      return new Promise(reslove => {
        this.$api("common.getEntLyEnumList")
          .invoke({ fkEntid: val })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    children: [],
                    icon: "iconyuanquan",
                    // menuid: "q" + (i + 1),
                    menuid: e.pkId,
                    menuname: e.menuname,
                    menutitle: e.menuname,
                    navigateurl: e.formurl,
                    sysmodel: "qiangdufenxi"
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateShebeiQdMenus", {
                  data: arr,
                  tongjiId: this.VUE_APP_TONGJIID
                });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    },
    getMenuFormList(val) {
      return new Promise(reslove => {
        this.$api("common.getMenuFormList")
          .invoke({ fkEntid: val, typecode: 1 })
          .then(({ data: { code, data } }) => {
            if (code === 1) {
              reslove();
              if (data) {
                let arr = [];
                let obj = {};
                data.map((e, i) => {
                  obj = {
                    // children: [],
                    icon: "",
                    // menuid: "q" + (i + 1),
                    moduleid: e.pkId,
                    menuName: e.menuname,
                    menutitle: e.menuname,
                    navigateUrl: e.formurl,
                    sysmodel: "",
                    URLTYPE: 0
                  };
                  arr.push(obj);
                });
                this.$store.commit("updateTongjiTopMenus", {
                  data: arr,
                  tongjiId: this.VUE_APP_TONGJIID
                });
              }
            } else {
            }
          })
          .catch(() => {});
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      let query = vm.$route.query;
      // 碳达峰
      if (query.isDf) {
        await vm.$store.dispatch("getPowerMenus", 21);
      } else if (query.isDfJC) {
        // 碳达峰决策
        await vm.$store.dispatch("getPowerMenus", 25);
      } else if (query.isQD) {
        // 碳清单
        await vm.$store.dispatch("getPowerMenus", 26);
      } else if (query.isTan) {
        // 碳排放平台
        await vm.$store.dispatch("getPowerMenus", 19);
      }
      const userInfo = await vm.$store.dispatch("getCacheUser");
      if (query.isTan) {
        await vm.getBaseFillForm(userInfo.entId);
        await vm.getEntMenuList(userInfo.entId);
        await vm.getEntLyEnumList(userInfo.entId);
        await vm.getMenuFormList(userInfo.entId);
      }
      sessionStorage.setItem("userName", userInfo.username);
      sessionStorage["userid"] = userInfo.userid;
      const topMenuData = vm.$store.state.menus.yjTopMenuData;
      if (topMenuData && topMenuData.length) {
        const { moduleid, sysmodel, navigateUrl } =
          vm.getFirstPath(topMenuData);
        vm.$router.push({
          path: navigateUrl,
          query: { moduleid, sysmodel, ...query }
        });
      } else {
        vm.$message({
          showClose: true,
          message: "尚未配置任何权限，请联系管理人员!",
          type: "warning",
          duration: 3000
        });
      }
    });
  }
};
</script>

<style scoped></style>
